import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { AnchorButton, Button, Intent, PopoverInteractionKind, Position, Tag } from '@blueprintjs/core';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import { Flex, Box } from 'components/flexbox';
import { DropdownMenu, KentikLogo } from 'components';
import Icon, { MaterialIcon } from 'components/Icon';
import store from 'stores/store';
import SudoDialog from 'views/Admin/Companies/components/SudoDialog';
import DebugModeDialog from 'views/Admin/DebugModeDialog';
import HelpDialog from 'views/Help/HelpDialog';
import AssetMigration from 'views/Library/AssetMigration';
import SearchDropdown from 'views/Search/SearchDropdown';
import BuildInfoDialog from 'components/BuildInfoDialog';
import DeviceLabelRegexDialog from 'views/Admin/DeviceLabelRegexDialog';

import ReportIssueDialog from './ReportIssueDialog';
import AnalyticsMenu from './AnalyticsMenu';
import TrialDaysRemaining from './TrialDaysRemaining';
import UserMenu from './UserMenu';
import SubtenantViewDialog from '../Admin/Subtenancy/SubtenantViewDialog';

const APP_ROUTES = [
  {
    name: 'Library',
    to: '/library',
    icon: 'folder-open'
  },
  {
    name: 'Data Explorer',
    to: '/explorer',
    icon: 'insert_chart',
    isMaterialIcon: true
  },
  {
    name: 'Alerting',
    to: '/alerting',
    icon: 'notifications',
    showAlertBadge: true,
    isMaterialIcon: true
  },
  {
    name: 'Analytics',
    icon: 'lightbulb_outline',
    menu: AnalyticsMenu,
    urlRoot: '/analytics',
    isMaterialIcon: true
  },
  {
    name: 'Admin',
    to: '/admin',
    icon: 'settings',
    adminOnly: true,
    isMaterialIcon: true,
    showRegexDialog: true
  }
];

@inject(
  '$app',
  '$auth',
  '$alerts',
  '$companySettings',
  '$devices',
  '$exports',
  '$onboarding',
  '$search',
  '$sudo',
  '$userGroups'
)
@observer
class NavMenu extends Component {
  handleLogout = async () => store.logout();

  handleNavLinkClick() {
    const { $app, $search } = this.props;
    $search.closeSearch();
    $app.closeHelp();
  }

  handleShowRegexDialogClick = e => {
    const { $companySettings } = this.props;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    $companySettings.setDeviceLabelRegexDialogOpen(true);
  };

  renderNavLink(route) {
    const { history, $alerts, $companySettings } = this.props;
    const Menu = route.menu;
    const LinkCmp = route.to ? NavLink : 'button';
    const NavIcon = route.isMaterialIcon ? MaterialIcon : Icon;
    const className = classNames('pt-dark pt-button pt-minimal nav-button', {
      'pt-active': history.location.pathname.startsWith(route.to || route.urlRoot)
    });

    const onClickHandler = route.to ? () => this.handleNavLinkClick() : () => {};

    const navLink = (
      <LinkCmp className={className} key={route.name} to={route.to} onClick={onClickHandler}>
        <NavIcon name={route.icon} title={route.name} />
        <span className="hidden-sm" style={{ marginLeft: '6px' }}>
          {route.name}
        </span>
        {route.showAlertBadge &&
          $alerts.alertCount > 0 && (
            <Tag className={`pt-small ${$alerts.alertIntent}`} style={{ marginLeft: '6px' }}>
              {$alerts.alertCount}
            </Tag>
          )}

        {route.showRegexDialog &&
          !$companySettings.deviceLabelRegexEnabled &&
          moment().isBefore('2019-03-01') && (
            <Button
              iconName="warning-sign"
              className="pt-small pt-minimal"
              intent={Intent.DANGER}
              style={{ marginLeft: 4, paddingBottom: 1, verticalAlign: 'initial' }}
              onClick={this.handleShowRegexDialogClick}
            />
          )}
        {route.menu && <span className="pt-icon-standard pt-icon-caret-down pt-align-right" />}
      </LinkCmp>
    );

    if (Menu) {
      return (
        <DropdownMenu
          key={route.name}
          content={Menu && <Menu history={history} />}
          interactionKind={PopoverInteractionKind.CLICK}
          tetherOptions={{ offset: '0 0' }}
        >
          {navLink}
        </DropdownMenu>
      );
    }

    return navLink;
  }

  renderNavLinks() {
    const {
      $onboarding: { forceOnboarding },
      $auth: { isAdministrator },
      $devices: { hasReceivedFlow }
    } = this.props;

    const menuItems = [];

    if (hasReceivedFlow) {
      APP_ROUTES.forEach(route => {
        if (!route.adminOnly || isAdministrator) {
          menuItems.push(this.renderNavLink(route));
        }
      });
    } else {
      APP_ROUTES.forEach(route => {
        if (!forceOnboarding && route.adminOnly && isAdministrator) {
          menuItems.push(this.renderNavLink(route));
        }
      });
    }

    return menuItems;
  }

  render() {
    const { $app, $auth, $sudo, location, $userGroups, $companySettings } = this.props;
    const { activeUser, activeUserDisplay, isActiveTrial } = $auth;

    if (location.pathname.startsWith('/login') || location.pathname === '/signup') {
      return null;
    }

    const className = classNames('pt-navbar portal-navbar');
    const buttonClassName = classNames('pt-dark', 'pt-minimal', 'nav-button', 'pt-intent-primary');
    const showNavLinks = $auth.authenticated && !$auth.twoFactorVerifyRequired;
    const hasSubtenancy =
      $auth.hasPermission('subtenancy.enabled') || $auth.hasPermission('subtenancy.trialMode') || isActiveTrial;
    return (
      <div>
        {$app.showV4Notice && (
          <div>
            <Box
              style={{
                height: 130,
                background: '#273142',
                top: 0,
                width: '100%'
              }}
            >
              <Flex align="center" flex={1}>
                <Box mx={2} p={2} style={{ height: 130 }}>
                  <svg width="215" height="98" viewBox="0 0 215 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_18_40)">
                      <path
                        d="M92.9768 32.4604C96.1307 31.6492 99.2934 31.1207 102.438 30.8564C97.8029 27.6302 97.5302 25.1877 97.2031 15.3997C96.9031 6.05824 89.205 1.30995 85.3968 0.10701C87.8962 4.70039 91.35 15.0716 85.2151 19.7833C79.8618 23.8844 79.7801 32.1596 80.607 37.1902C84.4514 35.1761 88.587 33.5812 92.9768 32.4604Z"
                        fill="#E14713"
                      />
                      <path
                        d="M74.6887 40.7545C68.3175 45.1472 62.9732 50.7522 58.9015 57.1497C55.6569 53.2127 51.5579 45.9855 54.1389 39.7428C57.0927 32.6069 48.8948 25.3524 44.4321 22.6185C48.3403 21.7618 57.3835 22.0352 62.3278 29.973C67.5357 38.3302 68.9901 40.2897 74.6887 40.7545Z"
                        fill="#E14713"
                      />
                      <path
                        d="M57.6199 87.5981C57.6472 65.5978 72.5162 45.549 94.8659 39.8236C121.379 33.0338 148.38 48.9646 155.188 75.4031C156.243 79.4769 156.742 83.5689 156.761 87.5972H209.293C211.974 87.5972 214.137 89.7753 214.137 92.4547C214.137 95.1431 211.974 97.3213 209.293 97.3213H5.70579C3.02472 97.3213 0.861572 95.1431 0.861572 92.4547C0.861572 89.7754 3.02469 87.5972 5.70579 87.5972L57.6199 87.5981Z"
                        fill="#FB6D3F"
                      />
                      <path
                        d="M50.0512 85.2021C50.3875 77.4555 52.3143 69.9549 55.5862 63.129C50.3874 65.5988 48.1607 64.6327 39.4356 59.9756C31.1648 55.5647 23.203 59.8389 20.2493 62.5275C25.4844 62.6733 36.2182 64.8697 37.2361 72.5252C38.1359 79.1965 45.2705 83.4067 50.0512 85.2021Z"
                        fill="#E14713"
                      />
                      <path
                        d="M131.44 36.051C124.632 32.8612 117.161 31.0019 109.454 30.6921C111.272 25.9256 115.48 18.8718 122.142 17.9878C129.813 16.967 132.012 6.26775 132.158 1.04561C134.857 3.98926 139.138 11.9364 134.721 20.175C130.122 28.7419 129.113 31.0292 131.44 36.051Z"
                        fill="#E14713"
                      />
                      <path
                        d="M137.719 39.4783C144.063 43.4699 149.644 48.7103 154.033 54.9622C154.552 49.5943 156.615 48.0906 164.813 43.0143C172.765 38.0838 173.047 29.0613 172.184 25.1699C169.439 29.6266 162.168 37.7924 155.015 34.8485C148.835 32.3059 141.691 36.252 137.719 39.4783Z"
                        fill="#E14713"
                      />
                      <path
                        d="M164.16 82.6777C163.887 79.6337 163.36 76.5715 162.57 73.5184C161.452 69.1439 159.852 65.0337 157.844 61.2061C162.906 60.4223 170.977 60.5954 175.03 65.8358C179.747 71.9602 190.145 68.506 194.753 66.0181C193.544 69.8185 188.782 77.4922 179.42 77.7929C169.841 78.1119 167.277 78.3759 164.16 82.6777Z"
                        fill="#E14713"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_18_40">
                        <rect width="215" height="98" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
                <Box flex={1}>
                  <h2 style={{ color: '#FB6D3F', marginBottom: 16 }}>Whoops!</h2>
                  <h4 style={{ color: 'white' }}>
                    You are currently using Kentik v3 which will no longer be available as of March 31, 2025
                  </h4>
                </Box>
                <Box mx={4}>
                  <Button
                    className="pt-large pt-intent-primary"
                    onClick={() => {
                      $app.goToV4();
                    }}
                  >
                    GO TO THE LATEST VERSION
                  </Button>
                </Box>
              </Flex>
            </Box>
            <Flex flex={1} style={{ background: '#FB6D3F', height: 20 }}>
              &nbsp;
            </Flex>
            <Flex flex={1} style={{ background: '#FA531C', height: 20 }}>
              &nbsp;
            </Flex>
            <Flex flex={1} style={{ background: '#BB3305', height: 20 }}>
              &nbsp;
            </Flex>
          </div>
        )}
        <Flex className={className} align="center" justify="space-between" px={2}>
          <Flex align="flex-start">
            <a href={window.location.origin}>
              <KentikLogo />
            </a>
          </Flex>

          <Flex align="center" flexAuto>
            {showNavLinks && this.renderNavLinks()}
          </Flex>

          {$auth.authenticated &&
            !$auth.twoFactorVerifyRequired && (
              <Flex align="center">
                <TrialDaysRemaining user={activeUser} />
                <SearchDropdown />

                <DropdownMenu
                  content={<UserMenu />}
                  position={Position.BOTTOM_RIGHT}
                  interactionKind={PopoverInteractionKind.CLICK}
                  tetherOptions={{ offset: '-4px 0' }}
                >
                  <AnchorButton
                    id="userMenu"
                    className={buttonClassName}
                    style={{ margin: 0, padding: '6px 5px 4px 7px', color: $auth.isSpoofed ? '#d65b4a' : '#ffffff' }}
                  >
                    <Flex align="center">
                      <div style={{ lineHeight: '15px', textAlign: 'left' }}>
                        {activeUserDisplay} <br />
                        <small className="pt-normal pt-text-muted">{activeUser.company.company_name_full}</small>
                      </div>
                      <span className="pt-icon-standard pt-icon-caret-down pt-align-right" />
                    </Flex>
                  </AnchorButton>
                </DropdownMenu>
              </Flex>
            )}

          {$auth.authenticated &&
            $auth.twoFactorVerifyRequired && (
              <div>
                <AnchorButton className={buttonClassName} onClick={this.handleLogout}>
                  <MaterialIcon style={{ margin: 0 }} name="exit_to_app" />
                </AnchorButton>
              </div>
            )}

          <ReportIssueDialog isOpen={$app.reportIssueDialogOpen} onClose={$app.toggleReportIssueDialog} />

          {($auth.hasSudo || $auth.isSpoofed) && (
            <SudoDialog isOpen={$sudo.sudoOpen} onClose={() => this.props.$sudo.setSudoOpen(false)} />
          )}

          {($auth.hasSudo || $auth.isSpoofed) && <DebugModeDialog isOpen={$app.debugModeOpen} />}

          {$app.showHelp &&
            $app.helpButtonSource === 'nav' && <HelpDialog isOpen response={$app.helpText} buttonSource="nav" />}

          {$sudo.assetMigrationOpen && <AssetMigration />}
          {hasSubtenancy && (
            <SubtenantViewDialog
              isOpen={$userGroups.subtenantViewDialogOpen}
              onClose={() => $userGroups.setSubtenantViewDialogOpen(false)}
            />
          )}

          {($auth.hasSudo || $auth.isSpoofed) && <BuildInfoDialog isOpen={$app.buildInfoOpen} />}
          <DeviceLabelRegexDialog isOpen={$companySettings.deviceLabelRegexDialogOpen} />
        </Flex>
      </div>
    );
  }
}

export default withRouter(NavMenu);
